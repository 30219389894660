class expandCollapseForm {

    constructor(){
        this.$h3s = app.DOM.form.find('h3');
        this.setupExpandIndividual()
        this.setupExpandAll()
    }

    setupExpandIndividual(){
        // individual state
        app.DOM.form.find('.btn-expand-collapse').on('click', function(){

            let $h3 = $(this).closest('h3');

            // some h3s are not direct sibling of next box
            if( $h3.hasClass('r') ) {
                $h3 = $h3.add($h3.closest('.tab').prev());
            }

            const state = ( $h3.hasClass('collapsed') ) ? 'collapsed' : 'expanded';

            if( state === 'collapsed' ) {
                $h3.removeClass('collapsed');
            } else {
                $h3.addClass('collapsed');
            }
        });
    }

    setupExpandAll(){
        const that = this;

        app.DOM.form.find('.btn-expand-collapse-all').on('click', function(){

            const state = ( $(this).hasClass('collapsed') ) ? 'collapsed' : 'expanded';

            if( state === 'collapsed' ) {
                that.$h3s.removeClass('collapsed');
                $(this).removeClass('collapsed');
            } else {
                that.$h3s.addClass('collapsed');
                $(this).addClass('collapsed');
            }
        });
    }
}

$(function(){
    new expandCollapseForm();
});